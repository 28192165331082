import * as React from "react"
import { SVGProps } from "react"

const PolygonIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7 6"
            fill="none"
            {...props}
        >
            <path fill="currentColor" d="M3.5 6 .036.75h6.928L3.5 6Z" />
        </svg>
    )

}


export default PolygonIcon