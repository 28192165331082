import * as React from "react"
import { SVGProps } from "react"

const PingIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23 32"
            fill="none"
            {...props}
        >
            <circle cx={11} cy={11} r={8} fill="currentColor" />
            <path
                fill="currentColor"
                d="M11.1 28S2 16.131 2 11.1a9.1 9.1 0 1 1 18.2 0c0 5.031-9.1 16.9-9.1 16.9Zm0-14.3a2.6 2.6 0 1 0 0-5.2 2.6 2.6 0 0 0 0 5.2Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M9.513 29.217 11.1 28l1.587 1.217-1.587 2.07-1.587-2.07Zm2.826-2.896C11.59 27.363 11.1 28 11.1 28s-.489-.637-1.24-1.68C7.372 22.868 2 14.966 2 11.1a9.1 9.1 0 1 1 18.2 0c0 3.865-5.371 11.767-7.86 15.22ZM1.611 16.71C.743 14.862 0 12.832 0 11.1a11.1 11.1 0 0 1 22.2 0c0 1.73-.743 3.76-1.61 5.612-.904 1.927-2.09 3.96-3.25 5.794a101.348 101.348 0 0 1-4.542 6.566l-.08.105-.022.029-.008.01L11.1 28l-1.587 1.217-.009-.011-.021-.029-.08-.105-.298-.398a101.337 101.337 0 0 1-4.245-6.168c-1.16-1.833-2.346-3.867-3.25-5.794Z"
                clipRule="evenodd"
            />
        </svg>
    )

}


export default PingIcon