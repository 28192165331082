import * as React from "react"
import { SVGProps } from "react"

const DateIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13 15"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M3.667 8.917a.684.684 0 0 1-.505-.204.689.689 0 0 1-.204-.505c0-.2.068-.368.204-.504a.687.687 0 0 1 .505-.204c.2 0 .368.068.505.204a.675.675 0 0 1 .203.504.699.699 0 0 1-.708.709Zm2.833 0a.684.684 0 0 1-.504-.204.689.689 0 0 1-.204-.505c0-.2.067-.368.204-.504A.687.687 0 0 1 6.5 7.5c.2 0 .368.068.505.204a.675.675 0 0 1 .203.504.699.699 0 0 1-.708.709Zm2.833 0a.684.684 0 0 1-.504-.204.689.689 0 0 1-.204-.505c0-.2.068-.368.204-.504a.687.687 0 0 1 .504-.204c.2 0 .368.068.505.204a.676.676 0 0 1 .204.504.7.7 0 0 1-.709.709ZM.125 14.583V1.833H2.25V.417h1.417v1.416h5.666V.417h1.417v1.416h2.125v12.75H.125Zm1.417-1.416h9.916V6.083H1.542v7.084Zm0-8.5h9.916V3.25H1.542v1.417Z"
            />
        </svg>
    )

}


export default DateIcon