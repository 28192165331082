import * as React from "react"
import { SVGProps } from "react"

const TrophyIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 22"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M13.843 14.415c5.766-.024 7.817-3.073 7.817-5.688a3.062 3.062 0 0 0-2.415-2.99c.252-.712.457-1.44.612-2.178a2.591 2.591 0 0 0-.536-2.167 2.644 2.644 0 0 0-2.056-.977H5.055a2.644 2.644 0 0 0-2.57 2c-.094.375-.101.766-.024 1.144.155.739.36 1.466.613 2.178A3.062 3.062 0 0 0 .66 8.727c0 2.615 2.05 5.664 7.816 5.688.033.184.052.37.059.557v2.943a1.648 1.648 0 0 1-1.75 1.75h-1.75v1.75h12.25v-1.75h-1.743a1.65 1.65 0 0 1-1.757-1.75v-2.943a3.95 3.95 0 0 1 .058-.557Zm4.754-7a1.312 1.312 0 0 1 1.313 1.312c0 1.792-1.427 3.697-5.359 3.916.155-.203.333-.387.532-.548a13.08 13.08 0 0 0 3.464-4.68h.05ZM2.41 8.727a1.313 1.313 0 0 1 1.312-1.312h.05c.788 1.8 1.972 3.4 3.463 4.68.2.16.378.345.533.548-3.931-.219-5.358-2.124-5.358-3.916Zm9.625 6.245v2.943a3.57 3.57 0 0 0 .426 1.75H9.859a3.57 3.57 0 0 0 .426-1.75v-2.943a5.37 5.37 0 0 0-1.957-4.244c-2.156-1.724-3.643-5.02-4.153-7.522a.836.836 0 0 1 .175-.708.9.9 0 0 1 .705-.333h12.21a.9.9 0 0 1 .868.667.84.84 0 0 1 .007.374c-.513 2.503-1.997 5.798-4.154 7.525a5.37 5.37 0 0 0-1.951 4.241Z"
            />
        </svg>
    )

}


export default TrophyIcon