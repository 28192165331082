import * as React from "react"
import { SVGProps } from "react"

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 18"
            fill="none"
            {...props}
        >
            <g fill="currentColor" clipPath="url(#a)">
                <path d="m16.124 7.581-3.506-3.506-1.001 1.004 3.045 3.046H4.688v1.417h9.976l-3.046 3.046 1.001 1.001 3.507-3.506a1.772 1.772 0 0 0 0-2.505l-.001.003Z" />
                <path d="M7.42 15.208a.708.708 0 0 1-.708.709H2.125a.708.708 0 0 1-.708-.709V2.458a.708.708 0 0 1 .708-.708h4.587a.708.708 0 0 1 .708.708v3.778h1.417V2.458A2.125 2.125 0 0 0 6.712.333H2.125A2.125 2.125 0 0 0 0 2.458v12.75a2.125 2.125 0 0 0 2.125 2.125h4.587a2.125 2.125 0 0 0 2.125-2.125v-3.777H7.42v3.777Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="currentColor" d="M0 .333h17v17H0z" />
                </clipPath>
            </defs>
        </svg>
    )

}


export default LogoutIcon