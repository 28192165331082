import * as React from "react"
import { SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M14.875 1.417H12.75V0h-1.417v1.417H5.667V0H4.25v1.417H2.125A2.125 2.125 0 0 0 0 3.542V17h17V3.542a2.125 2.125 0 0 0-2.125-2.125ZM1.417 3.542a.708.708 0 0 1 .708-.709h12.75a.708.708 0 0 1 .708.709v2.125H1.417V3.542Zm0 12.041v-8.5h14.166v8.5H1.417Z"
            />
            <path fill="currentColor" d="M6 8H3v3h3V8Z" />
        </svg>
    )

}


export default CalendarIcon