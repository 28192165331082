import * as React from "react"
import { SVGProps } from "react"

const MapIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19 18"
            fill="none"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="currentColor"
                    d="M17.875 9a.75.75 0 0 0-.75.75V12H12.56L8.125 7.565V1.5h.75a.75.75 0 1 0 0-1.5h-4.5a3.754 3.754 0 0 0-3.75 3.75v10.5A3.754 3.754 0 0 0 4.375 18h10.5a3.754 3.754 0 0 0 3.75-3.75v-4.5a.75.75 0 0 0-.75-.75Zm-13.5-7.5h2.25v5.925l-4.5 2.4V3.75c0-1.24 1.01-2.25 2.25-2.25Zm-2.25 12.75v-2.725l5.113-2.727 4.021 4.022L8.5 16.5H4.375a2.252 2.252 0 0 1-2.25-2.25Zm12.75 2.25h-4.5l2.25-3h4.5v.75c0 1.24-1.01 2.25-2.25 2.25Zm-1.912-8.108c.423.415.98.622 1.537.62a2.19 2.19 0 0 0 1.537-.62l1.38-1.351a4.095 4.095 0 0 0 1.208-2.917 4.095 4.095 0 0 0-1.208-2.916A4.097 4.097 0 0 0 14.5-.001a4.096 4.096 0 0 0-2.917 1.209 4.095 4.095 0 0 0-1.208 2.916c0 1.103.429 2.139 1.214 2.922l1.374 1.345Zm-.32-6.123A2.607 2.607 0 0 1 14.5 1.5c.702 0 1.36.273 1.856.769.497.496.769 1.155.769 1.856 0 .701-.273 1.36-.764 1.85L14.989 7.32a.701.701 0 0 1-.976 0l-1.368-1.339a2.608 2.608 0 0 1-.769-1.856c0-.701.273-1.36.769-1.856Zm.731 1.852a1.125 1.125 0 1 1 2.251 0 1.125 1.125 0 0 1-2.25 0Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="currentColor" d="M.625 0h18v18h-18z" />
                </clipPath>
            </defs>
        </svg>
    )
}


export default MapIcon