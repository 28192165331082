import * as React from "react"
import { SVGProps } from "react"

const DataIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M.416.417h14.167v14.166H.416V.417Zm1.417 1.416v11.334h11.333V1.833H1.833Zm1.416 2.48h8.5v1.416h-8.5V4.312Zm0 2.833h5.667v1.416H3.249V7.146Z"
            />
        </svg>
    )

}


export default DataIcon