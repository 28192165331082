import * as React from "react"
import { SVGProps } from "react"

const ReloadIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 15"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.174.774a6.97 6.97 0 0 1 7.488 2.661v-1.38a.654.654 0 0 1 1.307 0v3.702h-3.703a.653.653 0 0 1 0-1.307h1.505a5.663 5.663 0 1 0 .458 5.223.653.653 0 1 1 1.207.5 6.97 6.97 0 1 1-8.262-9.4Z"
                clipRule="evenodd"
            />
        </svg>
    )

}


export default ReloadIcon