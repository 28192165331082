import * as React from "react"
import { SVGProps } from "react"

const KeyIcon = (props: SVGProps<SVGSVGElement>) => {    
    return ( 
       <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23 22"
            fill="none"
            {...props}
        >            
            <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.723 6.836c0 .61 0 1.2.193 1.753-2.02 2.372-7.355 8.64-7.852 9.117a.946.946 0 0 0-.314.704c0 .398.244.782.45.998.31.323 1.631 1.547 1.875 1.313.722-.704.867-.891 1.162-1.182.446-.436-.046-1.326.108-1.687.155-.361.32-.431.586-.488.267-.056.74.136 1.111.14.39.005.6-.159.89-.43.235-.216.404-.418.409-.732.009-.421-.6-.98-.146-1.425.455-.445 1.111.291 1.594.235.483-.056 1.069-.727 1.13-1.013.06-.286-.549-1.022-.455-1.439.033-.14.319-.469.534-.515.216-.047 1.172.323 1.388.276.262-.056.567-.333.816-.487.726.314 1.387.44 2.236.44 3.21 0 5.812-2.503 5.812-5.587 0-3.085-2.602-5.577-5.813-5.577-3.21 0-5.714 2.502-5.714 5.586ZM18.25 5.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            />
        </svg>
    )
   
}


export default KeyIcon