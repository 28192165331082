import * as React from "react"
import { SVGProps } from "react"

const PersonIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M7.5 6.25c.619 0 1.212-.263 1.65-.732a2.594 2.594 0 0 0 .683-1.768c0-.663-.246-1.299-.683-1.768A2.257 2.257 0 0 0 7.5 1.25c-.62 0-1.213.263-1.65.732a2.594 2.594 0 0 0-.684 1.768c0 .663.246 1.299.684 1.768a2.257 2.257 0 0 0 1.65.732ZM5.166 7.656c-.589 0-1.158.232-1.598.652-.44.42-.721.997-.79 1.624l-.38 3.45a.493.493 0 0 0 .092.346.417.417 0 0 0 .615.078.478.478 0 0 0 .163-.314l.379-3.45a1.7 1.7 0 0 1 .348-.863c.192-.246.447-.425.734-.517v2.62c0 .562 0 1.03.047 1.402.049.392.157.747.423 1.031.266.285.596.401.963.454.346.05.784.05 1.307.05h.061c.525 0 .961 0 1.309-.05.365-.053.696-.169.962-.454.265-.284.373-.639.423-1.031.047-.371.047-.84.047-1.401V8.662c.286.091.541.27.734.516.192.245.313.546.348.864l.38 3.45a.494.494 0 0 0 .159.323.427.427 0 0 0 .327.103.42.42 0 0 0 .166-.059.448.448 0 0 0 .131-.122.483.483 0 0 0 .085-.354l-.38-3.45a2.642 2.642 0 0 0-.79-1.625 2.316 2.316 0 0 0-1.598-.652H5.166Z"
            />
        </svg>
    )

}


export default PersonIcon