import * as React from "react"
import { SVGProps } from "react"

const ArrowLinkIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 11"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.374 10.126a.531.531 0 0 1 0-.752l7.593-7.593H2.875a.531.531 0 0 1 0-1.062H9.25c.293 0 .531.238.531.531v6.375a.531.531 0 0 1-1.062 0V2.533l-7.593 7.593a.531.531 0 0 1-.752 0Z"
                clipRule="evenodd"
            />
        </svg>
    )

}


export default ArrowLinkIcon