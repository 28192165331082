import * as React from "react"
import { SVGProps } from "react"

const FilesIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 17"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M12.458 13.458H4.666c-.39 0-.723-.138-1-.415a1.367 1.367 0 0 1-.417-1.001V2.125c0-.39.14-.723.417-1a1.368 1.368 0 0 1 1-.417h4.958l4.25 4.25v7.084c0 .39-.138.723-.415 1-.278.278-.611.417-1.001.416ZM8.916 5.667V2.125h-4.25v9.917h7.792V5.667H8.916ZM1.833 16.292c-.39 0-.723-.139-1-.416a1.367 1.367 0 0 1-.417-1.001V4.958h1.417v9.917h7.791v1.417H1.833Z"
            />
        </svg>
    )

}


export default FilesIcon