import * as React from "react"
import { SVGProps } from "react"

const FileUploadIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 17"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M1.04 1.123A1.417 1.417 0 0 1 2.042.708h9.916a1.417 1.417 0 0 1 1.417 1.417V8.5a.708.708 0 1 1-1.417 0V2.125H2.042v12.75H7a.708.708 0 0 1 0 1.417H2.042a1.417 1.417 0 0 1-1.417-1.417V2.125c0-.376.15-.736.415-1.002Zm10.033 9.148c.391 0 .708.317.708.708v4.604a.708.708 0 1 1-1.416 0V10.98c0-.391.317-.708.708-.708Z"
                clipRule="evenodd"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.573 10.124a.708.708 0 0 1 1.001 0l1.594 1.594a.708.708 0 0 1-1.002 1.002l-1.092-1.093L9.98 12.72a.708.708 0 0 1-1.002-1.002l1.594-1.594ZM3.459 5.667c0-.391.317-.709.708-.709h5.667a.708.708 0 1 1 0 1.417H4.167a.708.708 0 0 1-.708-.708Zm0 2.833c0-.391.317-.708.708-.708h2.834a.708.708 0 1 1 0 1.416H4.167A.708.708 0 0 1 3.46 8.5Z"
                clipRule="evenodd"
            />
        </svg>
    )

}


export default FileUploadIcon