import * as React from "react"
import { SVGProps } from "react"

const EditIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.347 1.146a1.416 1.416 0 0 0-2.003 0L1.038 8.452a1.417 1.417 0 0 0-.415 1.002v3.706c0 .395.32.716.715.716l11.33-.001a.708.708 0 0 0 0-1.417H7.049l6.303-6.303a1.417 1.417 0 0 0 0-2.003l-3.005-3.006ZM5.046 12.458l7.305-7.305-3.006-3.005L2.04 9.454v3.004h3.006Z"
                clipRule="evenodd"
            />
        </svg>
    )

}


export default EditIcon