import * as React from "react"
import { SVGProps } from "react"

const PdfIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M15.938 9.563V8.5H12.75v5.313h1.063v-2.126h1.593v-1.062h-1.594V9.562h2.126Zm-5.844 4.25H7.969V8.5h2.125a1.595 1.595 0 0 1 1.594 1.594v2.125a1.595 1.595 0 0 1-1.594 1.594ZM9.03 12.75h1.063a.531.531 0 0 0 .531-.531v-2.125a.531.531 0 0 0-.531-.531H9.03v3.187ZM5.844 8.5H3.187v5.313H4.25v-1.594h1.594a1.064 1.064 0 0 0 1.062-1.063V9.562A1.062 1.062 0 0 0 5.844 8.5ZM4.25 11.156V9.562h1.594v1.594H4.25Z"
            />
            <path
                fill="#343434"
                d="M11.688 7.438V5.313a.483.483 0 0 0-.16-.372L7.81 1.22a.478.478 0 0 0-.372-.159H2.125a1.066 1.066 0 0 0-1.063 1.063v12.75a1.063 1.063 0 0 0 1.063 1.063h8.5v-1.063h-8.5V2.125h4.25v3.188a1.066 1.066 0 0 0 1.063 1.062h3.187v1.063h1.063Zm-4.25-2.125V2.337l2.974 2.974H7.438Z"
            />
        </svg>
    )

}


export default PdfIcon