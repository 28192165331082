import * as React from "react"
import { SVGProps } from "react"

const DollarIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 16"
            fill="none"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="currentColor"
                    d="M4.3 8.958c-1.233-.305-2.21-.75-2.934-1.333C.643 7.042.281 6.195.281 5.083c0-.889.275-1.597.824-2.125.549-.527 1.266-.875 2.15-1.041V0h2.57v1.917c.912.194 1.655.583 2.23 1.166.577.584.891 1.334.945 2.25H7.112c-.108-1.25-.965-1.875-2.572-1.875-.83 0-1.42.167-1.768.5-.348.334-.522.695-.522 1.084 0 .472.18.889.542 1.25.362.36 1.038.666 2.03.916 2.678.667 4.017 1.89 4.017 3.667 0 .806-.281 1.493-.843 2.063-.563.569-1.286.95-2.17 1.145V16H3.254v-1.958c-.857-.223-1.52-.64-1.988-1.25-.47-.611-.717-1.32-.744-2.125h1.889c.053 1.25.763 1.875 2.13 1.875.722 0 1.292-.153 1.707-.459.415-.305.623-.708.623-1.208 0-.417-.188-.77-.563-1.063-.375-.291-1.045-.576-2.009-.854Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="currentColor" d="M9 16H0V0h9z" />
                </clipPath>
            </defs>
        </svg>
    )

}


export default DollarIcon