import * as React from "react"
import { SVGProps } from "react"

const ExpandIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14"
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M1.75 13.75c-.413 0-.765-.147-1.059-.44a1.447 1.447 0 0 1-.441-1.06V1.75c0-.413.147-.765.441-1.059C.985.398 1.338.251 1.75.25H7v1.5H1.75v10.5h10.5V7h1.5v5.25c0 .412-.147.766-.44 1.06a1.44 1.44 0 0 1-1.06.44H1.75Zm3.525-3.975-1.05-1.05L11.2 1.75H8.5V.25h5.25V5.5h-1.5V2.8L5.275 9.775Z"
            />
        </svg>
    )

}


export default ExpandIcon